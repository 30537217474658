import './bootstrap';

// Bootstrap
import './slim.min';
import './popper.min';
// import './bootstrap.min';
import './bundle.min';

import './nav';
import './swiper';
import './quiz';


window.addEventListener('load', function () {
    document.getElementById('Mainloader').style.display = 'none';
    // var myElement = document.getElementById('footer');
    // var navbarWidth = window.matchMedia("(max-width:780px)");
    // var header = document.getElementById("myHeader");
    // if (header) {
    //     var sticky = header.offsetTop;
    // }
    // var prevScrollpos = window.pageYOffset;

    // window.onscroll = function () {
    //     var scrollBottom = $(window).height();
    //     var currentScrollPos = window.pageYOffset;
    //     var topPos = myElement.offsetTop;
    //     var xm = currentScrollPos + 900;

    //     if (prevScrollpos > currentScrollPos) {
    //         $(".secondStageNav").css("top", "86px");
    //         $(".triangleTeachers").css("opacity", "1");
    //     } else if (prevScrollpos <= currentScrollPos) {
    //         $(".secondStageNav").css("top", "0px");
    //         $(".secondStageNav").css("transition", "1s");
    //         $(".triangleTeachers").css("opacity", "0");
    //     } else {
    //         $(".secondStageNav").css("top", "0px");
    //         $(".secondStageNav").css("transition", "1s");
    //         $(".triangleTeachers").css("opacity", "0");
    //     }
    //     prevScrollpos = currentScrollPos;
    //     if (header) {
    //         if (window.pageYOffset > sticky && xm < topPos) {
    //             header.classList.add("sticky");
    //             header.classList.remove("sticky2");
    //         } else if (window.pageYOffset > sticky && xm > topPos) {
    //             header.classList.add("sticky2");
    //         } else {
    //             header.classList.remove("sticky");
    //             header.classList.remove("sticky2");
    //         }
    //     }
    // }


    function myFunction() {
        var x = document.getElementById("Drop");
        if (x.className.indexOf("w3-show") == -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    }

    if (this.document.getElementById('mobNavButton')) {
        this.document.getElementById('mobNavButton').addEventListener('click', function () {
            myFunction();
        })
    }

    window.addEventListener("load", (event) => {
        var activeVideo = document.getElementById('video_container');
        var activeImg = document.getElementById('fImg');
        var x = $('video').find('Source:first').attr('src');
        var y = $('#fImg').attr('src');
        if (x.length > 0 && y.length <= 0) {
            activeVideo.style.display = 'block';
            activeImg.style.display = 'none';
        } else {
            activeVideo.style.display = 'none';
            activeImg.style.display = 'block';
        }
    });

    var x = window.matchMedia("(max-width:780px)");
    var y = window.matchMedia("(min-width:1500px)");
    var Bar2 = document.getElementById('SideBar');
    var sideBar2 = document.getElementById('SideBox');
    var allButtons2 = document.querySelectorAll('.cartImg');
    var closeBar2 = document.getElementById('closeSection');

    for (var i = 0; i < allButtons2.length; i++) {
        allButtons2[i].addEventListener('click', function () {
            Bar2.style.visibility = 'visible';
            closeBar2.style.opacity = "1";
            closeBar2.style.transition = "0.6s";
            if (y.matches) {
                sideBar2.style.transform = "translate(0%, 0)";
                sideBar2.style.width = "25%";
                sideBar2.style.zIndex = "100000";
                sideBar2.style.visibility = "visible";
                sideBar2.style.transition = "1s";
            } else if (x.matches) {
                sideBar2.style.transform = "translate(0%, 0)";
                sideBar2.style.transition = "0";
                sideBar2.style.width = "100%";
                sideBar2.style.zIndex = "100000";
                sideBar2.style.visibility = "visible";
            } else {
                sideBar2.style.transform = "translate(0%, 0)";
                sideBar2.style.width = "45%";
                sideBar2.style.zIndex = "100000";
                sideBar2.style.visibility = "visible";
                sideBar2.style.transition = "1s";
            }
        });
    }

    var teachers = document.querySelectorAll('.cartImg');
    for (var teach = 0; teach < teachers.length; teach++) {
        var allTeachers = document.querySelectorAll('.cartImg')[teach];
        allTeachers.addEventListener('click', function () {
            $(this).addClass('activateTeach2');
        });
    }
    var closeTeacherMarker = document.getElementById('closeSection');
    if (closeTeacherMarker) {
        closeTeacherMarker.addEventListener('click', function () {
            $('.cartImg').removeClass('activateTeach2');
            sideBar2.style.transform = "translate(100%, 0)";
            sideBar2.style.width = "0";
            Bar2.style.visibility = "hidden";
            closeBar2.style.opacity = "0";
            closeBar2.style.transition = "0.6s";
        });
    }

    var closeTeacherMarker2 = document.getElementById('closeSideBtn');
    if (closeTeacherMarker2) {
        closeTeacherMarker2.addEventListener('click', function () {
            $('.cartImg').removeClass('activateTeach2');
            sideBar2.style.transform = "translate(100%, 0)";
            sideBar2.style.width = "0";
            Bar2.style.visibility = "hidden";
            closeBar2.style.opacity = "0";
            closeBar2.style.transition = "0.6s";
        });
    }

    var switchBtn1 = document.getElementById('switchBtn1');
    var switchBtn2 = document.getElementById('switchBtn2');
    var signTeen = document.getElementById('signTeen');
    var signAdult = document.getElementById('signAdult');

    if (switchBtn1) {
        switchBtn1.addEventListener('click', () => {
            switchBtn1.classList.add('activeSwitch');
            switchBtn2.classList.remove('activeSwitch');
            signAdult.classList.remove('SignOff');
            signTeen.classList.add('SignOff');
        });
    }

    if (switchBtn2) {
        switchBtn2.addEventListener('click', () => {
            switchBtn1.classList.remove('activeSwitch');
            switchBtn2.classList.add('activeSwitch');
            signAdult.classList.add('SignOff');
            signTeen.classList.remove('SignOff');
        })
    }

    var TestRes = document.getElementById('TestRes');
    var Res = document.getElementById('Res');
    var submRes = document.getElementById('submRes');

    if(submRes) {
        submRes.addEventListener("click", function (event) {
            event.preventDefault();
            TestRes.style.display = 'none';
            Res.style.display = 'block';
            console.log('clicked');
        });
    }


    var limitText = $('#limitText').text();
    if (limitText.length > 24)
        $('#limitText').text(limitText.substring(0, 24) + '...');

    var limitText2 = $('#limitText2').text();
    if (limitText2.length > 24)
        $('#limitText2').text(limitText2.substring(0, 24) + '...');

    var limitText3 = $('#limitText3').text();
    if (limitText3.length > 24)
        $('#limitText3').text(limitText3.substring(0, 24) + '...');

    var limitText4 = $('#limitText4').text();
    if (limitText4.length > 24)
        $('#limitText4').text(limitText4.substring(0, 24) + '...');

    var limitText5 = $('#limitText5').text();
    if (limitText5.length > 24)
        $('#limitText5').text(limitText5.substring(0, 24) + '...');

    var limitText6 = $('#limitText6').text();
    if (limitText6.length > 24)
        $('#limitText6').text(limitText6.substring(0, 24) + '...');

    var txt = $('.h1-responsive').text();
    if (txt.length > 100)
        $('.h1-responsive').text(txt.substring(0, 100) + '.....');

    var indexCards = $('#h1-responsive2').text();
    if (indexCards.length > 170)
        $('#h1-responsive2').text(indexCards.substring(0, 170) + '.....');

    var indexCards2 = $('#h1-responsive3').text();
    if (indexCards2.length > 170)
        $('#h1-responsive3').text(indexCards2.substring(0, 170) + '.....');

    var indexCards3 = $('#h1-responsive4').text();
    if (indexCards3.length > 170)
        $('#h1-responsive4').text(indexCards3.substring(0, 170) + '.....');

    var indexCards4 = $('#h1-responsive5').text();
    if (indexCards4.length > 170)
        $('#h1-responsive5').text(indexCards4.substring(0, 170) + '.....');

    var indexCards5 = $('#h1-responsive6').text();
    if (indexCards5.length > 170)
        $('#h1-responsive6').text(indexCards5.substring(0, 170) + '.....');

    var indexCards6 = $('#h1-responsive7').text();
    if (indexCards6.length > 170)
        $('#h1-responsive7').text(indexCards6.substring(0, 170) + '.....');

});
